<template>
  <div>
    <ParcelDetail
      :data="detail"
      v-if="detail"
      v-bind="$attrs"
      v-on="$listeners"
      @update="fetchData"
    />
    <v-card height="400" v-else>
      <div style="height: 400px" class="d-flex fill-height align-center justify-center">
        <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
      </div>
    </v-card>
  </div>
</template>

<script>
import Parcel from '../../services/parcel';
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    ParcelDetail: () => import('./Detail.vue')
  },
  computed: {
    detail: {
      get() {
        return this.$store.state.parcel.detail.data;
      },
      set(value) {
        this.$store.commit('setParcelDetail', { key: 'data', value });
      }
    }
  },
  data() {
    return {
      p: null
    };
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    stop() {
      if (this.p) {
        this.p.unsubscribe();
      }
    },
    fetchData() {
      this.stop();
      const id = this.id;
      this.detail = null;
      this.p = new Parcel({ id });
      try {
        this.p.onSnapshot(data => {
          this.detail = { ...data, id };
        });
      } catch (error) {
        console.warn(error);
      }
    }
  },
  watch: {
    id: {
      handler: 'fetchData',
      immediate: true
    }
  }
};
</script>

<style>
</style>
